import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fullJourneyData: [],
    allItineraries: [],
    selectedItinerary: '',
    speedScale: [],
    paddleScale: [],
    dayPart: 'day',
    showLabels: true,
    startDate: 0,
    endDate: 0,
    paddleToggle: true,
    markerToggle: true,
    portToggle: true,
    timeAtToggle: true,
    storyToggle: false,
    phraseToggle: false,
    currentToggle: true,
    zoomLevel: 1,
    speedLevel: 0,
    siteData: {},
    depthAverage: 0,
    tempAverage: 0
  },
  getters: {
    currentState (state) {
      return state.currentToggle
    },
    storyState (state) {
      return state.storyToggle
    },
    timeAtState (state) {
      return state.timeAtToggle
    },
    // phraseState (state) {
    //   return state.phraseToggle
    // },
    portState (state) {
      return state.portToggle
    },
    // paddleState (state) {
    //   return state.paddleToggle
    // },
    markerState (state) {
      return state.markerToggle
    },
    dayPart (state) {
      return state.dayPart
    },
    zoomLevel (state) {
      return parseInt(state.zoomLevel, 10) - 1
    },
    controlSpeedLevel (state) {
      let obj = {
        '.5': 1,
        '1': 2,
        '1.5': 3
      }
      return obj[`${state.speedLevel}`]
    },
    speedLevel (state) {
      return state.speedLevel * 0.5
    },
    speedValue (state) {
      return state.speedScale
    },
    siteData (state) {
      return state.siteData
    },
    journeyData (state) {
      return state.fullJourneyData
    },
    allItineraries (state) {
      return state.allItineraries
    },
    selectedItinerary (state) {
      return state.selectedItinerary
    },
    ports (state) {
      return state.fullJourneyData.filter(segment => segment.story !== null)
    },
    depthAverage (state) {
      return state.depthAverage
    },
    tempAverage (state) {
      return state.tempAverage
    }
  },
  mutations: {
    setTimeOfDayMut (state, part) {
      state.dayPart = (part === 'day') ? 'day' : 'night'
    },
    setShowLabelsMut (state, label) {
      state.showLabels = label
    },
    setZoomLevelMut (state, level) {
      state.zoomLevel = level
    },
    setSpeedLevelMut (state, level) {
      //state.speedLevel = level
      console.log(level)
      // let obj = {
      //   '1': .5,
      //   '2': 1,
      //   '3': 1.5
      // }
      state.speedLevel = level // obj[`${level}`]
    },
    setPaddleMut (state, value) {
      state.paddleToggle = value
    },
    setMarkerMut (state, value) {
      console.log('marker state updated')
      state.markerToggle = value
    },
    setPortMut (state, value) {
      state.portToggle = value
    },
    setTimeAtMut (state, value) {
      state.timeAtToggle = value
    },
    setPhraseMut (state, value) {
      state.phraseToggle = value
    },
    setStoryMut (state, value) {
      state.storyToggle = value
    },
    setCurrentMut (state, value) {
      state.setCurrentToggle = value
    },
    setSpeedScaleMut (state, value) {
      state.speedScale = value
      console.log('speed scale state updated', state)
    },
    setPaddleScaleMut (state, value) {
      state.paddleScale = value
      console.log('paddle scale state updated', state)
    },
    setSiteDataMut (state, value) {
      state.siteData = value
      console.log('site data state updated', state)
    },
    setJourneyDataMut (state, value) {
      state.fullJourneyData = value
      console.log('journey data state updated', state)
    },
    setJourneySiteSpeedMut (state, value) {
      console.log('set site speed value', value)
      let newArr = []
      state.fullJourneyData.forEach(site => {
        // console.log(value[`${site.id}`])

        if (site.type === 'travel') {
          console.log('travel', value[`${site.id}`].siteSpeeds)
          console.log('travel', value[`${site.id}`].averageSpeed)
          console.log('travel', value[`${site.id}`])
          if (value[`${site.id}`].siteSpeeds.length != 0) {
            console.log('assigning store values')
            newArr.push({
              ...site,
              speed: value[`${site.id}`].siteSpeeds,
              averageSpeed: value[`${site.id}`].averageSpeed
            })
          } else {
            newArr.push(site)
          }
        } else {
          newArr.push(site)
        }

      })
      console.log('newArr', newArr)
      state.fullJourneyData = newArr
      console.log('Journey speed data state updated', state)
    },
    setDepthAverageMut (state, value) {
      state.depthAverage = value
      console.log('depthAverage')
    },
    setTempAverageMut (state, value) {
      state.tempAverage = value
      console.log('tempAverageMut')
    },
    setAllItinerariesMut (state, value) {
      state.allItineraries = value
      console.log('allItinerariesMut')
    },
    setSelectedItineraryMut (state, value) {
      // window.location.pathname = value.id
      window.location.pathname.includes('itinerary') ? window.history.pushState( null , '', value.id ) :       window.history.pushState( null , '', `itinerary/${value.id}` );
      state.selectedItinerary = value
      console.log('setSelectedItineraryMutt')

    }
  },

  actions: {
    setJourneySpeedValue ({ commit }, level) {
      commit('setJourneySiteSpeedMut', level)
    },
    setTimeOfDay ({ commit }, part) {
      commit('setTimeOfDayMut', part)
    },
    setShowLabels ({ commit }, label) {
      commit('setShowLabelsMut', label)
    },
    setZoomLevel ({ commit }, level) {
      commit('setZoomLevelMut', parseInt(level, 10))
    },
    setSpeedLevel ({ commit }, level) {
      commit('setSpeedLevelMut', parseFloat(level, 10))
    },
    setPaddleState ({ commit }, level) {
      commit('setPaddleMut', level)
    },
    setMarkerState ({ commit }, level) {
      commit('setMarkerMut', level)
    },
    setPortState ({ commit }, level) {
      commit('setPortMut', level)
    },
    setTimeAtState ({ commit }, level) {
      commit('setTimeAtMut', level)
    },
    setPhraseState ({ commit }, level) {
      commit('setPhraseMut', level)
    },
    setStoryState ({ commit }, level) {
      commit('setStoryMut', level)
    },
    setCurrentState ({ commit }, level) {
      commit('setCurrentMut', level)
    },
    setSpeedScaleValue ({ commit }, level) {
      commit('setSpeedScaleMut', level)
    },
    setPaddleScaleValue ({ commit }, level) {
      commit('setPaddleScaleMut', level)
    },
    setSiteData ({ commit }, level) {
      commit('setSiteDataMut', level)
    },
    setDepthAverage ({ commit }, level) {
      commit('setDepthAverageMut', level)
    },
    setTempAverage ({ commit }, level) {
      commit('setTempAverageMut', level)
    },
    setJourneyData ({ commit }, level) {
      commit('setJourneyDataMut', level)
    },
    setAllItineraries ({ commit }, data) {
      commit('setAllItinerariesMut', data)
    },
    setSelectedItinerary ({ commit }, data) {
      commit('setSelectedItineraryMut', data)
    }
  }
})
