<template>
  <div :id="`port-${id}`" class="port_story_three">
    <div class="port_story_three_image" :class="[`story_image_${index + 1}`]" v-for="(image, index) in storyImages"
         :key="`three-${index}`" :style="`background-image:url(${getImage(image.path)})`">
      <MileMarker v-if="index === 1" :mile="mile" :label="label"/>
    </div>

    <div :class="`story_three_phrase_${index + 1}`" v-for="(phrase, index) in story.headline" :key="`phrase-${index}`">
      <template v-if="index === 1">
        <span class="paddle">A</span>
      </template>
      <template class="paddle_wheel" v-if="index === 2">
        <span class="paddle">I</span>
        <span class="paddle">L</span>
        <span class="paddle">S</span>
        <span
          class="paddle">A</span>
      </template>
      <span class="label">{{ upper(phrase.value) }}</span>
      <template class="paddle_wheel" v-if="index === 1">
        <span class="paddle">I</span>
        <span class="paddle">L</span>
        <span class="paddle">S</span>
        <span
          class="paddle">I</span>
        <span class="paddle">L</span>
      </template>
    </div>
  </div>
</template>

<script>
import utilities from '../mixins/utilities'
import MileMarker from './MileMarker'
import { mapGetters} from 'vuex'

export default {
  name: 'PortStoryThree',
  mixins: [utilities],
  components: {
    MileMarker
  },
  props: {
    id: String,
    label: String,
    headline: String,
    story: Object,
    images: Array,
    phrases: Array,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['storyState']),
    storyImages () {
      return this.images.filter((el, index) => index < 3)
    }
  }
}
</script>
