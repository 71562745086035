export const baseDataPath = 'https://data.aq-design-system.mrmclient.com';
export const apiKey = '3e6a7463f1d3c1494d6103a54c7c2d';

export const queryGetAllItineraries = {
  header: {
    Authorization: "Basic MTM3Nzc1OmpmZGQzamdyc3NmZXdmcmZycmU4Zg=="
  },
  operationName:"searchItineraries",
  query: `query searchItineraries {
  searchItineraries(propertyId:"haq") {
      id
      localizedInfo {
        locale
        values {
          id
          value
        }
      }
      itineraryDayItem {
        settings {
          id
          value
        }
      }
    }
  }`
};
export const queryGetPortById = {
  header: {
    Authorization: "Basic MTM3Nzc1OmpmZGQzamdyc3NmZXdmcmZycmU4Zg=="
  },
  operationName:"searchPorts",
  query: `query searchPorts {
  searchPorts(propertyId:"haq") {
      id
      localizedInfo {
        locale
        values {
          id
          value
        }
      }
    }
  }`
};
export const queryGetItineraryById = {
  header: {
    Authorization: "Basic MTM3Nzc1OmpmZGQzamdyc3NmZXdmcmZycmU4Zg=="
  },
  operationName:"searchItinerariesQuery",
  variables:{
    "propertyId":"haq",
    "itineraryId":"",
  },
  query: `query searchItinerariesQuery($propertyId: String!, $itineraryId: String) {
  searchItineraries(propertyId:$propertyId, id: $itineraryId) {
    propertyId
    localizedInfo {
      locale
      values {
        id
        value
      }
    }
    itineraryDayItem {
      settings {
        id
        value
      }
      media {
        type
        url
        caption
        sortOrder
      }
      port {
        id
        propertyId
        settings {
          id
          value
        }
        localizedInfo {
          locale
          values {
            id
            value
          }
        }
        media {
          type
          url
          caption
          sortOrder
        }
      }
    }
  }
}`
}
