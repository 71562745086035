<template>
<transition name="fade">
  <svg v-show="markerState" class="mile_marker" width="100" height="100" viewBox="0 0 100 100" fill="none">
    <rect width="100" height="100" fill="#C10016"/>
    <path transform="translate(2, 0)"
        d="M58.4424 20.2423C58.2903 20.0833 58.1359 19.9266 57.9816 19.7815C56.7518 18.602 55.3257 17.6461 53.7675 16.9567C53.567 16.8669 53.3643 16.7839 53.1569 16.7033C51.5686 16.0854 49.8841 15.7508 48.1802 15.7148C48.0696 15.7148 47.9613 15.7148 47.8484 15.7148C47.7355 15.7148 47.618 15.7148 47.5189 15.7148C45.815 15.7508 44.1306 16.0854 42.5422 16.7033C42.3349 16.7839 42.1321 16.8669 41.9317 16.9567C40.373 17.6467 38.9463 18.6025 37.7153 19.7815C37.5563 19.9335 37.4019 20.0902 37.2545 20.2423"
        stroke="white" stroke-miterlimit="10"/>
    <text x="50" y="60" text-anchor="middle" class="mile-number" fill="white">
      {{ mile }}
    </text>
    <text x="50" y="83" text-anchor="middle" class="port-name" fill="white">
      {{ upper(label) }}
    </text>
  </svg>
</transition>
</template>

<script>
import utilities from '../mixins/utilities'
import { mapGetters} from 'vuex'

export default {
  name: 'MileMarker',
  mixins: [utilities],
  props: {
    label: String,
    mile: String
  },
  computed: {
    ...mapGetters(['markerState']),
  }
}
</script>
