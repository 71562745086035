<template>
  <div :id="`port-${id}`" class="port_story_two_stagger">
    <div class="story_image" :class="[`story_image_${index + 1}`]" v-for="(image, index) in images.slice(0,2)" :key="`image${index}`"
         :style="`background-image:url(${getImage(image.path)})`"></div>

    <div class="story_paddles story_paddles_left">
      <span class="paddle">S</span>
      <span class="paddle">A</span>
      <span class="paddle">I</span>
      <span class="paddle">L</span>
    </div>

    <div class="story_paddles story_paddles_center">
      <span class="paddle white">S</span>
      <span class="paddle">A</span>
      <span class="paddle">I</span>
      <span class="paddle">L</span>
      <span class="paddle white">S</span>
    </div>

    <div class="story_paddles story_paddles_right">
      <MileMarker :mile="mile" :label="label"/>
    </div>

    <div class="port_story_headline">
      <h2>
        <template v-for="(copy, index) in story.headline">
          {{ copy.value }}<br :key="`br${index}`">
        </template>
      </h2>
    </div>
  </div>
</template>

<script>
import utilities from '../mixins/utilities'
import MileMarker from './MileMarker'
import { mapGetters} from 'vuex'

export default {
  name: 'PortStoryTwoStagger',
  mixins: [utilities],
  components: {
    MileMarker
  },
  props: {
    id: String,
    label: String,
    headline: Array,
    story: Object,
    images: Array,
    phrases: Array,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['storyState'])
  }
}
</script>
