<template>
  <div :id="`port-${id}`" class="port_story_diag_left">
    <div class="story_image" :class="[`story_image_${index + 1}`]" v-for="(image, index) in storyImages"
         :key="`three-${index}`" :style="`background-image:url(${getImage(image.path)})`">
      <MileMarker v-if="index === 0" :mile="mile" :label="label"/>

      <div class="story_phrases" :class="`story_phrase_${index + 1}`">
        <template v-if="index === 0">
          <span class="label">
            <span class="large" :class="{'white':idx === 0}"
                  v-for="(char, idx) in stringSplit(upper(story.highlights[0].value.text))"
                  :key="`char1${idx}`">
              {{ char }}
            </span>
          </span>
          <span class="paddle">S</span>
          <span class="paddle">A</span>
          <span class="label"
            v-for="(char1, idx1) in stringSplit(upper(story.highlights[1].value.text), ' ')"
            :key="`char1${idx1}`">{{ char1 }}</span>
          <span class="paddle">L</span>
          <span class="paddle">S</span>
          <span></span>
        </template>

        <template v-if="index === 1">
          <span></span>
          <span class="paddle">S</span>
          <span class="paddle">A</span>
          <span class="paddle">I</span>
          <span class="paddle">L</span>
          <span class="large" :class="{'white':idx >= stringSplit(story.highlights[2].value.text).length - 2}"
                v-for="(char, idx) in stringSplit(upper(story.highlights[2].value.text))"
                :key="`char1${idx}`">
            {{ char }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import utilities from '../mixins/utilities'
import MileMarker from './MileMarker'
import { mapGetters} from 'vuex'

export default {
  name: 'PortStoryTwoDiagLeft',
  mixins: [utilities],
  components: {
    MileMarker
  },
  props: {
    id: String,
    label: String,
    story: Object,
    images: Array,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['storyState']),
    storyImages () {
      return this.images.filter((el, index) => index < 2)
    }
  }
}
</script>
