<template>
  <div :id="`port-${id}`" class="port_story_two_even">

    <div class="story_image" :class="[`story_image_${index + 1}`]" v-for="(image, index) in images.slice(0,2)"
         :key="`image${index}`" :style="`background-image:url(${getImage(image.path)})`"></div>

    <div class="story_paddles story_paddles_left">
      <span class="paddle">I</span> <span class="paddle">L</span>
    </div>

    <div class="story_paddles story_paddles_center">
      <span class="paddle white">S</span> <span class="paddle">A</span>
      <MileMarker :mile="mile" :label="label"/>
      <span class="paddle">L</span> <span class="paddle white">S</span>
    </div>

    <div class="story_paddles story_paddles_right">
      <span class="paddle">L</span> <span class="paddle">I</span>
    </div>

    <div class="port_story_two_even_headline">
      <h2 v-for="(copy, index) in story.headline" :key="`head${index}`">
        {{ copy.value }}
      </h2>

<!--      <h2>{{ story.headline[0].value }} br-->
<!--      <p v-if="story.headline.length > 1">{{ story.headline[1].value }}</p>-->
<!--      </h2>-->
    </div>
  </div>
</template>

<script>
import utilities from '../mixins/utilities'
import MileMarker from './MileMarker'
import { mapGetters} from 'vuex'

export default {
  name: 'PortStoryTwoEven',
  mixins: [utilities],
  components: {
    MileMarker
  },
  props: {
    id: String,
    label: String,
    headline: String,
    story: Object,
    images: Array,
    phrases: Array,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['storyState'])
  }
}
</script>
