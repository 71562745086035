<template>
  <div id="app" :class="[`zoom-${zoomLevel}`, dayPart]">

    <router-view @RefreshToApp="refresh" :key="componentKey" v-if="!isFetching"/>
    <!-- <Main :key="componentKey" v-if="!isFetching"/> -->
    <div class="loading" v-else>
      Loading...
    </div>

<!-- <div v-if="missingPorts.length > 0 && !isFetching">
  <h2>Ports missing in MRM database</h2>
    <ul>
      <li v-for="port in missingPorts" :key="port.id">{{port.name}} (id:{{port.id}})</li>
    </ul>
  </div> -->
  </div>
</template>

<script>
// import Main from './components/main.vue'
import axios from 'axios'
import { mapActions, mapState, mapGetters} from 'vuex'
import { baseDataPath, apiKey, queryGetItineraryById, queryGetAllItineraries, queryGetPortById } from '@/config.js'

export default {
  name: 'App',
  data () {
    return {
      velCode: '00060',
      tempCode: '00010',
      depthCode: '00065',
      componentKey: 0,
      isFetching: true,
      journeySitesIDs: [],
      jSiteData: {},
      speedScale: [],
      paddleScale: [],
      journey: [],
      itinerary: [],
      allPorts: [],
      allPortsFromAQ: [],
      allItineraries: [],
      missingPorts: []
    }
  },
  created () {
    this.fetchAllItineraries()
  },
  watch: {
    dayPart (val) {
      document.documentElement.setAttribute('class', val)
    }
  },
  computed: {
    ...mapState(['zoomLevel']),
    ...mapGetters(['dayPart', 'selectedItinerary']),
    sitesForAPI: function () {
      return this.journeySitesIDs.join()
    }
  },
  methods: {
    ...mapActions(['setDepthAverage', 'setTempAverage', 'setJourneyData', 'setSpeedScaleValue', 'setPaddleScaleValue', 'setSiteData', 'setJourneySpeedValue', 'setAllItineraries', 'setSelectedItinerary']),

    //Get all the itineraries to build out a selector
    fetchAllItineraries: function() {
      axios.post('https://my.hornblower.com/graphql', queryGetAllItineraries)
      .then(res =>{
        this.allItineraries = res.data.data.searchItineraries.map(itinerary => {
            return {
              id: itinerary.id,
              name: this.getValue(itinerary.localizedInfo[0].values, 'itineraryName')
            }
          });

          //Filter out the ones with DELETED in their name
          this.allItineraries = this.allItineraries.filter(itinerary => {
            return itinerary.name.indexOf('DELETE') === -1;
          })

          //Sort alphabetically
        this.allItineraries.sort(function(a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });
        this.setAllItineraries(this.allItineraries);


        //If there is an itinerary ID in the URL, set it as selected and use that as our loaded voyage
        if(this.$route.params.itineraryId) {
          const selectedItinerary = this.allItineraries.filter(itinerary => itinerary.id === this.$route.params.itineraryId);
          if(selectedItinerary[0] && selectedItinerary.length > 0) {
            this.setSelectedItinerary(selectedItinerary[0])
          }
        }

        //Then get all the ports from AQ
        this.fetchAllPorts()

      })
      .catch(err=>{
        console.log(err)
      })
    },
    refresh: function(){
      this.isFetching = true;
      this.fetchDataFromAQ(this.selectedItinerary.id)
      //this.componentKey++
    },
    //Get all the ports
    fetchAllPorts: function() {
      axios.post('https://my.hornblower.com/graphql', queryGetPortById)
      .then(res =>{
        this.allPortsFromAQ = res.data.data.searchPorts.map(port => {
          return {
            id: port.id,
            name: this.getValue(port.localizedInfo[0].values, 'portName')
          }
        });

        //Then get all the data from our Cockpit CMS
        this.fetchDataFromCMS('voyage01');

        // console.log(portsArray);
      })
      .catch(err=>{
        console.log(err)
      })
    },


    //Get data from the CMS
    fetchDataFromCMS: function (slug) {
      axios.get(baseDataPath + '/api/collections/get/journeys?populate=2&filter[slug]=' + slug + '&token=' + apiKey)
      .then(res => {
        //console.log(res);
        if (res.status === 200 && res.data.entries.length > 0) {
          this.allPorts = res.data.entries[0].ports;
          //If there is a selected itinerary, use that.  Otherwise skip over it and just use the allPorts for the journey
          if(this.selectedItinerary) {
            this.fetchDataFromAQ(this.selectedItinerary.id)
          } else {
            this.processJourney(this.allPorts);
          }

        } else {
          //TODO - figure out what to do with an API error or voyage not found
        }
        //
      })
    },

    fetchDataFromAQ: function(itineraryId) {
      queryGetItineraryById.variables.itineraryId = itineraryId;

      axios.post('https://my.hornblower.com/graphql', queryGetItineraryById)
      .then(res =>{
        //console.log('Hernandez', 'before process', this.journey)
        this.processJourney(res.data.data.searchItineraries[0].itineraryDayItem);
        //console.log('Voyage', res.data.data.searchItineraries[0].itineraryDayItem)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //Utility function since graphQL result values can't be properly accessed
    getValue(arr, key) {
      if(arr.filter(el => el.id === key)[0]) {
        return arr.filter(el => el.id === key)[0].value;
      } else {
        return null;
      }
    },

    processJourney: function (itinerary) {
      this.journey = [];
      //console.log('Hernandez', 'Journey1', this.journey)
      this.missingPorts = [];
      //console.log(this.allPorts)

      // console.log('Hernandez', 'Journeys', this.journey)
      //       this.allPorts.forEach(port =>{
      //   console.log('Hernandez', 'port name', port.name, port.aq_id)
      //   })

      if(this.selectedItinerary) {
        //Build a journey object from the itinerary and the ports we store in Cockpit
        //console.log('Hernandez', itinerary)

        itinerary.forEach(itinerary => {
          //console.log('Hernandez', 'Itin', itinerary)
          //Some ports don't have cities... so skip them?
          //console.log(this.getValue(itinerary.settings, 'itineraryCityName'));
          //if(this.getValue(itinerary.settings, 'itineraryCityName') !== null) {
            const portId = this.getValue(itinerary.settings, 'itineraryPortId');
            //Find the object in our allPort array from Cockpit where the aq_id matches the portId
            // console.log('Hernandez', 'All Ports', this.allPorts)


            const match = this.allPorts.filter(port => port.aq_id === portId);
            //console.log("Hernandez", 'Match', match)
            if(match && match.length > 0) {
              this.journey.push(match[0]);
            } else {

              let portFound = false;

              //If the port ID doesn't match, check the ID against the all port list to get the port name
              const match2 = this.allPortsFromAQ.filter(port => port.id === portId);
              if(match2 && match2.length > 0) {
                const portName = match2[0].name;
                let alreadyInJourney = false;
                // this.journey.forEach(port =>{
                //   if(portName.includes(port.name)){
                //     alreadyInJourney = true;
                //   }
                // })
                if(this.journey.length > 0 && portName.includes(this.journey[this.journey.length - 1].name)){
                  alreadyInJourney = true
                }
                //Check if there are any other ports in the all port from AQ list that contain that name
                const match3 = this.allPortsFromAQ.filter(port => {

                  let strippedPortName = port.name.replace(/Hotel Stay in/g, '').trim();
                  strippedPortName = strippedPortName.split(',')[0];
                  //console.log(strippedPortName, portName);
                  return portName.lastIndexOf(strippedPortName) !== -1;
                });

                //console.log('match3', match3);

                if(!alreadyInJourney && match3 && match3.length > 0) {
                  //check each of the matches against our Cockpit ports and see if we have a match there
                  match3.forEach(port1 => {
                    const match4 = this.allPorts.filter(port2 => port2.aq_id === port1.id);
                    if(match4 && match4.length > 0) {
                      portFound = true;
                      //console.log('match4', match4)
                      this.journey.push(match4[0]);
                    }
                  });

                  //Finally, if the port wasn't found at all, add it to an array so that we can track down content for it.
                  if(!portFound) {
                    let match5 = this.missingPorts.filter(port => port.id === portId);
                    //console.log('match5', match5)

                    if(match5.length === 0) {
                      this.missingPorts.push({
                        id: portId,
                        name: portName,
                        type: 'Missing in MRM database'
                      });
                    }
                  }
                }


              } else {
                this.missingPorts.push({
                  id: portId,
                  name: '',
                  type: 'Missing in AQ database'
                });
              }

            }
            //
          //}
        });
      } else {
        //If there is no selected itinerary just use all the ports as is
        this.journey = itinerary;
      }


      console.log('Missing Ports', this.missingPorts)

      this.retrieveDefaultSites(this.journey);

      // this.jsSiteData = journey;

      //Figure out the distances between each port and add a default speed array
      for (var i = 0, max = this.journey.length; i < max; i++) {
        //Check if we are not on the last port
        if (i != this.journey.length - 1) {
          this.journey[i].distance = Math.abs(this.journey[i].mile - this.journey[i + 1].mile)
        } else {
          this.journey[i].distance = 0
        }
      }
      this.calcPaddleScale(this.journey)
      //This is now an array of ports with distance....  what next?

      // console.log(this.journey)
      //Figure out the scale so we know how many paddles each port should have

      //Populate random default speeds for each port to fill out arrays based on the distance

      //Query the API to get the 'real' speeds and update the values

      // this.retrieveDefaultSites(journey)
      // this.retrieveDistances(journey)

      // if (window.localStorage.getItem('journeySitesIDs')) {
      //   // this.journeySitesIDs = JSON.parse(window.localStorage.getItem(`journeySitesIDs`))
      //   // this.setSpeedScaleValue(JSON.parse(window.localStorage.getItem(`speedScale`)))
      //   this.setJourneySpeedValue(JSON.parse(window.localStorage.getItem('journeyData')))
      //   // this.setJourneySpeedValue(setJourneySpeedValue)
      //   this.isFetching = false
      // } else {
      //   console.log('first instance of jsitedata', this.jSiteData)
      //
      //   console.log('Retrieve journey data', journey)
      this.startDate = this.formatDate()
      this.endDate = this.formatDate()
      this.dataAPICall()
      //   console.log('retrieving and formating current date for default journey data', this.startDate, this.endDate)
      //   console.log('sites before api call', this.sitesForAPI)
      //   // this.setSiteData(this.jSiteData)
      //   console.log('jsite data before pushing it to global store', this.jSiteData)
      //   this.setJourneySpeedValue(this.jSiteData)
      //
      // }
      //this.forceRerender()

      this.isFetching = false
    },

    forceRerender: function () {
      this.componentKey += 1
    },

    retrieveDefaultSites: function (data) {
      // let tempJSiteDate = this.jSiteData
      // let tempIDStore = []
      data.forEach(sites => {
        this.journeySitesIDs.push(sites.id)
        //console.log('journeySitesIDs', this.journeySitesIDs);
        // tempJSiteDate[`${sites.id}`] =
        // {
        //   distance: 0,
        //   siteSpeeds: [],
        // }
      })
      // console.log('Collect site codes from journey data', this.journeySitesIDs)
      // this.journeySitesIDs = tempIDStore
      window.localStorage.setItem(`journeySitesIDs`, JSON.stringify(this.journeySitesIDs))

    },
    // retrieveDistances: function (data) {
    //   let tempJSiteDate = this.jSiteData
    //   console.log('jsite data in distance', this.jSiteData)
    //   let tempDistanceStore = []
    //   data.forEach(sites => {
    //     if (sites.distance) {
    //       tempDistanceStore.push(sites.distance)
    //       tempJSiteDate[`${sites}`] = this.jSiteData[`${sites.id}`].distance = sites.distance
    //     }
    //   })
    //   console.log('Collect distance values from journey data set', tempDistanceStore)
    //   this.calcPaddleScale(tempDistanceStore)
    // },
    dataAPICall: async function () {
      let res = await axios.get('https://waterservices.usgs.gov/nwis/iv/', {
        params: {
          'format': 'json',
          'sites': this.sitesForAPI,
          'startDT': this.startDate,
          'endDT': this.endDate,
          'siteStatus': 'all'
        }
      })
      // .then(res => {
      let data = res.data.value.timeSeries
      // console.log('retrieve raw data from api', data)
      let streamData = this.filterData(data, this.velCode)
      let temperatureData = this.filterData(data, this.tempCode)
      let depthData = this.filterData(data, this.depthCode)
      // console.log('filter out relevent stream data', streamData)
      this.calculateSpeedScale(streamData)
      this.assignSpeedData(streamData)
      temperatureData.forEach(temp => {
        this.assignTempData(temp)
      })

      depthData.forEach(depth => {
        this.assignDepthData(depth)
      })
      this.setTempAverage()
      this.setTempAverage(this.calcTempAverage(this.journey))
      this.setDepthAverage(this.calcDepthAverage(this.journey))
      // console.log(depth)

      await this.setJourneyData(this.journey)
      this.$nextTick(() => {
        this.forceRerender()
      })

      // console.log(this.journey)
      // })
      // .catch(err => console.log(err))
    },
    assignDepthData: function (data) {
      let dataSiteCode = data.sourceInfo.siteCode[0].value
      let ind = 0
      this.journey.forEach(site => {
        if (site.id === dataSiteCode) {
          // console.log('depth', parseInt(data.values[0].value[0].value, 10))
          if(data.values.length > 0 && data.values[0].value.length > 0) {
            this.journey[ind]['depth'] = parseInt(data.values[0].value[0].value, 10)
            return true
          }
        }
        ind++
      })
    },
    assignTempData: function (data) {
      let dataSiteCode = data.sourceInfo.siteCode[0].value
      let ind = 0
      this.journey.forEach(site => {
        if (site.id === dataSiteCode) {
          if(data.values.length > 0 && data.values[0].value.length > 0) {
            this.journey[ind]['temperature'] = data.values[0].value[0].value
            return true
          }
        }
      })
    },
    calcTempAverage: function (data) {
      // console.log('data', data)
      let totalTemp = 0
      data.forEach(temp => {
        if(data.values.length > 0 && data.values[0].value.length > 0) {
          totalTemp += parseInt(temp.values[0].value[0].value, 10)
        }
      })

      return totalTemp > 0 ? totalTemp / numberOfPorts : 12
    },
    calcDepthAverage: function (journey) {
      let totalDepth = 0,
          numberOfPorts = 0;
      journey.forEach(port => {
        numberOfPorts += port.depth ? 1 : 0;
        totalDepth += port.depth ? parseInt(port.depth) : 0;
      })
      return totalDepth > 0 ? totalDepth / numberOfPorts : 30
    },
    formatDate: function () {
      var d     = new Date(),
          month = '' + (d.getMonth() + 1),
          day   = '' + d.getDate(),
          year  = d.getFullYear()

      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day

      return [year, month, day].join('-')
    },
    filterData: function (data, code) {
      return data.filter(item => item.variable.variableCode[0].value === code)
    },
    selectSpeeds: function (speeds, wheelNumber) {
      // console.log('speeds', speeds)
      let selectedSpeeds = []
      if (speeds.length > 0) {
        selectedSpeeds.push(speeds[0].value)

        selectedSpeeds.push(speeds[Math.floor(speeds.length / 13)].value)
        for (let i = 0; i <= wheelNumber - 3; i++) {
          if (i == wheelNumber / 2) {
            selectedSpeeds.push(speeds[Math.floor(speeds.length / 2)].value)
          } else {
            selectedSpeeds.push(speeds[Math.floor(speeds.length / 13) * i + 1].value)
          }
        }
        selectedSpeeds.push(speeds[speeds.length - 1].value)
        // console.log('selected speeds', selectedSpeeds)
      } else {
        let randomCount = Math.floor(Math.random() * (8 - 4) + 3)
        for (let i = 0, max = randomCount; i < max; i++) {
          let randomSpeed = Math.floor(Math.random() * (13 - 2) + 2)
          selectedSpeeds.push(randomSpeed)
        }
      }
      return selectedSpeeds

      // let convertedContainer = []
      // selectedSpeeds.forEach(speed=>{
      //    convertedContainer.push(this.convertSpeed(speed))
      // })

      // return convertedContainer
    },
    convertSpeed: function (speed) {
      speed = parseInt(speed, 10)
      // console.log('this is speed in convertSpeed', speed)
      let output = null
      let counter = 0
      this.speedScale.forEach(ind => {
        if (speed < this.speedScale[0]) {
          output = 1
        } else if (speed > ind && speed < this.speedScale[counter + 1]) {
          output = counter + 1
        } else if (speed > this.speedScale[this.speedScale.length - 1]) {
          output = this.speedScale.length - 1
        }
        counter++
      })
      return output
    },
    calcWheelNumber: function (site) {
      let currentSite = site
      this.journey.forEach(ind => {
        if (ind.id == site) {
          currentSite = ind
        }
      })
      let counter = 0
      this.paddleScale.forEach(ind => {
        if (currentSite.distance >= ind && currentSite.distance < this.paddleScale[counter + 1]) {
          return counter + 2
        }
        counter++
      })
      return counter + 2
    },
    calcAverageSpeed: function (speeds) {
      const reducer = (previousValue, currentValue) => parseInt(previousValue, 10) + parseInt(currentValue, 10)
      let average = Math.floor(speeds.reduce(reducer) / speeds.length)
      // console.log('this is average speed', average)
      return average
    },
    assignSpeedData: function (data) {
      //console.log('this.journey', this.journey);
      //console.log('this.journeySitesIDs', this.journeySitesIDs);
      this.journeySitesIDs.forEach(site => {
        let siteData = data.filter(item => item.sourceInfo.siteCode[0].value == `${site}`)
        // console.log('check', site)
        let wheelNumber = this.calcWheelNumber(site)

        //find indicy of current site ID in order to later assign data
        let ind = null
        let counter = 0
        this.journey.forEach(currentJourney => {
          if (currentJourney.id == site) {
            ind = counter
          }
          counter++
        })

        if (siteData.length !== 0 && ind && ind < this.journey.length) {

          siteData.forEach((site) => {
            let convertedContainer = []
            let selected = this.selectSpeeds(site.values[0].value, wheelNumber)
            // console.log('this is temp selected speeds', selected)
            selected.forEach(speed => {
              convertedContainer.push(this.convertSpeed(speed))
            })
            //console.log('ind', ind)
            this.journey[ind]['averageSpeed'] = this.calcAverageSpeed(selected)
            this.journey[ind]['siteSpeeds'] = convertedContainer
          });



        } else if (ind) {
          //console.log('ind2', ind)
          // let average = Math.floor(speeds.reduce(reducer) / speeds.length)
          this.journey[ind]['siteSpeeds'] = new Array(Math.floor(Math.random() * (8 - 3) + 3))

          for (let i = 0; i <= this.journey[ind]['siteSpeeds'].length - 1; i++) {
            this.journey[ind]['siteSpeeds'][i] = this.convertSpeed(Math.floor(Math.random() * (100000 - 3000) + 3000))
          }
          // console.log(this.journey[ind]['siteSpeeds'])
          // this.journey[ind]['siteSpeeds'].forEach(speed =>{

          //   this.journey[ind]['siteSpeeds'][i] = Math.Floor(Math.random() * (100000 - 3000) + 3000)
          // })
          const reducer = (previousValue, currentValue) => parseInt(previousValue, 10) + parseInt(currentValue, 10)
          this.journey[ind]['averageSpeed'] = Math.floor(this.journey[ind]['siteSpeeds'].reduce(reducer) / this.journey[ind]['siteSpeeds'].length)
        }
      })

      this.journey.forEach(stop => {
        if(!stop.siteSpeeds) {
          stop.siteSpeeds = [2,5]
        }
      })
      window.localStorage.setItem('journeyData', JSON.stringify(this.journey))
      //this.forceRerender()
      // console.log('Assign selected speed data to its corresponding site for further reference/change in component', this.jSiteData)
    },
    calcPaddleScale: function (data) {
      let allDistances = []
      data.forEach(site => {
        allDistances.push(site.distance)
      })
      // console.log('all distances', allDistances)

      let tempScaleStore = []
      let highest = Math.max(...allDistances)
      let lowest = Math.min(...allDistances)
      let intervalIncrement = Math.floor(highest / 5)
      let intervals = lowest
      for (let i = 1; i <= 5; i++) {
        intervals += intervalIncrement
        i === 1 ? tempScaleStore.push(lowest) : tempScaleStore.push(intervals)
      }
      // this.setPaddleScaleValue(tempScaleStore)
      this.paddleScale = tempScaleStore
      // console.log('Calculate Paddle Scale based off of distance data', tempScaleStore)
    },
    calculateSpeedScale: function (data) {
      // console.log('this is data in speed scale', data)
      let allSpeeds = []
      let tempSpeedScale = []
      data.forEach(site => {
        let temp = site.values[0].value
        temp.forEach(speed => {
          allSpeeds.push(parseInt(speed.value, 10))
        })

      })
      // console.log('combine all data from each site/strech of journey', allSpeeds)
      let highest = Math.max(...allSpeeds)
      let lowest = Math.min(...allSpeeds)
      //if lowest value is negative offset highest value by the absolute value of lowest
      if (Math.sign(lowest) === -1) {
        highest += Math.abs(lowest)
      }
      let intervalIncrement = Math.floor(highest / 13)
      let intervals = lowest
      for (let i = 1; i <= 13; i++) {

        intervals += intervalIncrement
        i === 1 ? tempSpeedScale.push(lowest) : tempSpeedScale.push(intervals)
      }
      this.speedScale = tempSpeedScale
      window.localStorage.setItem(`speedScale`, JSON.stringify(tempSpeedScale))
      // console.log('create scale that correlates to stream speed range within data set', tempSpeedScale)
    }
  }
}
</script>

<style>
.demo {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input {
  width: 260px;
  padding: 0.5rem;
}

ul {
  width: 100%;
  color: rgba(30, 39, 46,1.0);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 .5rem 0;
}
li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest { width: 100%; display: block;}
.autosuggest__results-item--highlighted {
  background-color: rgba(51, 217, 178,0.2);
}
</style>
