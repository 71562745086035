<template>

  <span :id="`port-${id}`" class="port_stop">
        <transition name="fade">
            <span v-if="portState" class="port_label">
              {{ upper(label) }}
            </span>
        </transition>

        <span class="port_stop_mile" :style="{'margin-left':portState?spacingCalc(lastItem(previousSpeed)):null}">
            <transition name="fade">
              <svg :width="timeAtState ? length : 140" :height="timeAtState ? 140 : 40"
                   :viewBox="`0 0 ${timeAtState ? length : 140} ${timeAtState ? 94 : 40}`">
                <path v-show="markerState" :transform="`translate(${timeAtState ? length/2:45},0) translate(-9,0)`"
                      d="M18.9395 4.84624C18.8103 4.71118 18.6791 4.57808 18.548 4.45477C17.5032 3.45279 16.2917 2.64071 14.9679 2.05503C14.7977 1.97869 14.6254 1.90822 14.4492 1.83971C13.0999 1.31477 11.6689 1.03056 10.2213 1C10.1274 1 10.0354 1 9.93946 1C9.84355 1 9.74372 1 9.65956 1C8.21199 1.03056 6.781 1.31477 5.43162 1.83971C5.25546 1.90822 5.08322 1.97869 4.91293 2.05503C3.58881 2.64118 2.37674 3.4532 1.33093 4.45477C1.19587 4.58395 1.06472 4.71705 0.939453 4.84624"/>
                <text v-show="markerState" :x="timeAtState ? length/2 : 45" y="32" text-anchor="middle">
                {{ mile }}
                </text>
                  <rect v-if="timeAtState" x="0" y="42" height="9" :width="length"/>
              </svg>
            </transition>
        </span>

  </span>

</template>

<script>
import { mapGetters } from 'vuex'
import utilities from '../mixins/utilities'

export default {
  name: 'PortStop',
  mixins: [utilities],
  props: {
    id: String,
    label: String,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    },
    images: Array,
    story: Object,
    previousSpeed: Array
  },
  computed: {
    ...mapGetters(['markerState', 'timeAtState', 'portState']),
    speed () {
      let cssClass = 'speed_'
      const randNum = Math.floor(Math.random() * 13)
      cssClass += randNum
      return cssClass
    },
    length () {
      return (Number(this.time) / 10) * 9
    }
  }
}
</script>
