<template>
  <div class="main">
    <ControlPanel/>

    <!-- <ItinerarySelector @refresh="this.emitRefresh"/> -->

    <transition-group name="list" tag="div" class="content">
      <template v-for="(segment, rootIdx) in journeyData">
        <!-- Ports with stories -->
        <template v-if="segment.story !== null">
          <component :is="getComponent(segment)" :key="`port${rootIdx}`" :mile="segment.mile" :label="segment.name"
                     :images="segment.story.images" :story="segment.story" :time="segment.time_at_site" :id="segment.id"
                     :previousSpeed="rootIdx > 0 ? journeyData[rootIdx - 1].siteSpeeds : []"/>
        </template>

        <!-- Ports without stories -->
        <template v-else>
          <PortStop :id="segment.id" :mile="segment.mile" :label="segment.name" :time="segment.time_at_site"
                    :key="`port-stop${rootIdx}`"
                    :previousSpeed="rootIdx > 0 ? journeyData[rootIdx - 1].siteSpeeds : []"/>
        </template>

        <!-- Travel segment - ports that have a 'distance' -->

        <!-- No phrases -->
        <template v-if="segment.distance > 0 && segment.siteSpeeds && segment.siteSpeeds.length > 0">
          <template v-if="segment.phrase === null">
            <PaddleWheel v-for="(wheel, wheelIdx) in segment.siteSpeeds" :speed="wheel" where="no-phrases"
                         :first="wheelIdx === 0" :end-cap="wheelIdx !== segment.siteSpeeds.length - 1"
                         :key="`wheel${rootIdx}-${wheelIdx}`"/>
          </template>

          <!-- With phrases -->
          <template v-else-if="segment.phrase">

            <PaddleWheel v-for="(wheel, wheelIdx) in getFirstHalfOfSpeed(segment.siteSpeeds)" :speed="wheel"
                         where="fist-half" :first="wheelIdx === 0" :end-cap="wheelIdx !== segment.siteSpeeds.length - 1"
                         :key="`p1-wheel${rootIdx}-${wheelIdx}`"/>

            <template v-for="(p, wheelIdx) in segment.phrase.lines">
              <PaddleWheel :label="p.value.line" :small-label="p.value.size === 'small'" where="middle"
                           :end-cap="wheelIdx !== segment.siteSpeeds.length - 1"
                           :speed="segment.siteSpeeds[wheelIdx] + 5" :key="`p2-wheel${rootIdx}-${wheelIdx}`"/>
            </template>

            <PaddleWheel v-for="(wheel, wheelIdx) in getSecondHalfOfSpeed(segment.siteSpeeds)" :speed="wheel"
                         where="second-half" :end-cap="wheelIdx !== getSecondHalfOfSpeed(segment.siteSpeeds).length - 1"
                         :key="`p3-wheel${rootIdx}-${wheelIdx}`"/>
          </template>
        </template>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ControlPanel from './ControlPanel'
import ItinerarySelector from './ItinerarySelector'
import PaddleWheel from './PaddleWheel'
import PortStop from './PortStop'
import PortImageSingle from './PortImageSingle'
import PortStoryFull from './PortStoryFull'
import PortStoryThree from './PortStoryThree'
import PortStoryTwoEven from './PortStoryTwoEven'
import PortStoryTwoStagger from './PortStoryTwoStagger'
import PortStoryTwoDiagLeft from './PortStoryTwoDiagLeft'
import PortStoryTwoDiagRight from './PortStoryTwoDiagRight'
import utilities from '../mixins/utilities'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Main',
  mixins: [utilities],
  components: {
    ControlPanel,
    ItinerarySelector,
    PaddleWheel,
    PortStop,
    PortImageSingle,
    PortStoryFull,
    PortStoryThree,
    PortStoryTwoEven,
    PortStoryTwoStagger,
    PortStoryTwoDiagLeft,
    PortStoryTwoDiagRight
  },
  props: {
    componentKey: Number
  },
  computed: {
    ...mapGetters(['dayPart', 'zoomLevel', 'journeyData', 'storyState', 'depthAverage', 'tempAverage'])
  },
  methods: {
    ...mapActions(['setSpeedScaleValue', 'setPaddleScaleValue', 'setSiteData']),
    getComponent (obj) {
      return obj.story.templates[this.zoomLevel].value
    },
    getFirstHalfOfSpeed (speed) {
      if (speed && speed.length > 0) {
        return speed.slice(0, Math.ceil(speed.length / 2))
      } else {
        return speed
      }
    },
    getSecondHalfOfSpeed (speed) {
      if (speed && speed.length > 0) {
        return speed.slice(Math.ceil(speed.length / 2) + 1)
      } else {
        return speed
      }
    },
    getSlowSpeedRandom () {
      return Math.floor(Math.random() * (13 - 5) + 4)
    },
    emitRefresh () {
      this.$emit('RefreshToApp')
    }
  },
  mounted () {
    console.log(this.journeyData)
    this.$nextTick(() => {
      gsap.set('span > span:not(.paddle_wheel)', { clipPath: 'inset(0 100% 0 0)' })
      ScrollTrigger.batch('span > span:not(.paddle_wheel)', {
        interval: 0.75,
        onEnter: batch => gsap.to(batch, { clipPath: 'inset(0 0% 0 0)', stagger: 0.015 })
      })

      // const targets = gsap.utils.toArray('span > span:not(.paddle_wheel)')
      // let i = 1
      // // let o = 1
      // targets.forEach(target => {
      //   if (this.isInViewport(target)) {
      //     gsap.from(target, {
      //       duration: 0.3,
      //       clipPath: 'inset(0 100% 0 0)',
      //       delay: 0.05 * i
      //     })
      //     i += 0.5
      //   } else {
      //     gsap.from(target, {
      //       duration: 0.3,
      //       clipPath: 'inset(0 100% 0 0)',
      //       // delay: 0.05 * o,
      //       scrollTrigger: target
      //     })
      //     // o += 0.5
      //   }
      // })
    })
  }
}
</script>
