<template>
  <div :id="`port-${id}`" class="port_story_full" :class="{'black':images[images.length - 1].meta.bgColor}" :style="`background-image:url(${getImage(images[images.length - 1].path)});`">
      <div class="port_story_full_label" :class="[images[images.length - 1].meta.bgPosition, images[images.length - 1].meta.labelColor]">
        <h3>
          <template v-for="(text) in story.headline">
            {{ text.value }}
          </template>
        </h3>
        <p>{{ story.body }}</p>
    </div>

    <MileMarker class="port_story_full_mile" :mile="mile" :label="label"/>
  </div>
</template>

<script>
import utilities from '../mixins/utilities'
import MileMarker from './MileMarker'
import { mapGetters} from 'vuex'

export default {
  name: 'PortStoryFull',
  mixins: [utilities],
  components: {
    MileMarker
  },
  props: {
    id: String,
    label: String,
    labelColor: {
      type: String,
      default: 'white'
    },
    phrases: Array,
    story: Object,
    images: Array,
    bgColor: String,
    mile: {
      type: String,
      default: '1'
    },
    time: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['storyState'])
  }
}
</script>
