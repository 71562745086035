<template>
  <transition name="fade">
  <span v-show="paddleToggle" class="paddle_wheel" :class="[`speed_${this.speed}`,where]">
<!--    <span v-if="cap !== 'end'" :style="{'letter-spacing':spacingCalc(speed}">S</span>-->
    <span :style="{'letter-spacing':spacingCalc(speed), 'margin-left':first?spacingCalc(speed):null}">A</span>
    <span class="label" :class="[{'label-small':smallLabel}]" v-if="label !== 'I' && phraseToggle"
          :style="{'margin-right':spacingCalc(speed)}">
      <span v-for="(char, index) in stringSplit(label, ' ')" :key="`char${index}`">{{ char }}</span>
    </span>
    <span v-else :style="{'letter-spacing':spacingCalc(speed)}">{{ phraseToggle ? label : 'I' }}</span>
    <span :style="{'letter-spacing':spacingCalc(speed)}">L</span>
    <span :style="{'letter-spacing':spacingCalc(speed)}" v-if="endCap">S</span>
  </span>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import utilities from '@/mixins/utilities.js'

export default {
  name: 'PaddleWheel',
  mixins: [utilities],
  props: {
    siteID: Number,
    wheelNumber: Number,
    label: {
      type: String,
      default: 'I'
    },
    first: Boolean,
    where: String,
    cap: {
      type: String,
      default: 'end'
    },
    smallLabel: Boolean,
    endCap: {
      type: Boolean,
      default: false
    },
    speed: {
      type: Number,
      default: 1
    }
  },
  computed: {
    ...mapState(['paddleToggle', 'phraseToggle']),
    showLabel () {
      return this.label !== 'I' && this.showLabels
    }
  }
}
</script>
