import { mapGetters } from 'vuex'
import { baseDataPath } from '@/config.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  computed: {
    ...mapGetters(['speedLevel'])
  },
  methods: {
    upper (val) {
      return String(val).toUpperCase()
    },
    stringSplit (val, separator) {
      return String(val).split(separator || '')
    },
    getImage (img_path) {
      //return require(`@/assets/images/${img_path}`)
      return String(baseDataPath + img_path)
    },
    randomSpeed () {
      let cssClass = 'speed_'
      const randNum = Math.floor(Math.random() * 13)
      cssClass += randNum
      return cssClass
    },
    logThis (val) {
      console.log(val)
      return val
    },
    inchToMicron (val) {
      return val * 25400
    },
    isInViewport (element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      )
    },
    lastItem (arr) {
      return (arr) ? arr[arr.length - 1] : null
    },
    spacingCalc (speed) {
      let speedRamper = 20
      if (this.speedLevel > 0) {
        speedRamper = 200
      }
      return (this.speedLevel * speedRamper) + (speed * 15) + 'px'
    },
    cToF (celsius) {
      return celsius * 9 / 5 + 32
    },
    clearAnimationSetup () {
      gsap.set('span > span:not(.paddle_wheel)', { clipPath: '' })
      ScrollTrigger.getAll().forEach(st => st.kill())
    },
    reveal (targets, done) {
      gsap.set(targets, { clipPath: 'inset(0 100% 0 0)' })
      gsap.to(targets, { clipPath: 'inset(0 0% 0 0)', onComplete: done })
    },
    wipeAway (targets, done) {
      gsap.to(targets, { clipPath: 'inset(0 100% 0 0)', onComplete: done })
    }
  }
}
