import Vue from 'vue'
import VueAnimate from 'vue-animate-scroll'
import App from './App.vue'
import router from './router.js'
import './assets/css/main.scss'
import store from './store'
import VueMousetrapPlugin from 'vue-mousetrap/vue2'

Vue.config.productionTip = false

Vue.use(VueMousetrapPlugin)
Vue.prototype.$mode = process.env.VUE_APP_MODE
Vue.prototype.$build = process.env.NODE_ENV

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueAnimate);
