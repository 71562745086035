<template>
  <span class="port_stop port_single_image">

    <span v-if="portState" class="port_label">
      {{ upper(label) }}
    </span>

    <span class="port_stop_image" :class="[images[0].meta.color]" :style="`background-image:url(${getImage(images[0].path)});`">
      <svg width="250" height="250" viewBox="0 0 250 250">
        <path transform="translate(125,125) translate(-21,-36)"
              d="M40.375 9.41365C40.0924 9.11821 39.8055 8.82705 39.5186 8.5573C37.2333 6.36549 34.5831 4.58906 31.6873 3.30787C31.3148 3.14088 30.938 2.98672 30.5527 2.83686C27.6009 1.68856 24.4706 1.06686 21.3041 1C21.0986 1 20.8973 1 20.6875 1C20.4777 1 20.2593 1 20.0752 1C16.9087 1.06686 13.7784 1.68856 10.8266 2.83686C10.4413 2.98672 10.0645 3.14088 9.69198 3.30787C6.79547 4.59008 4.14406 6.36637 1.85635 8.5573C1.56091 8.8399 1.27403 9.13106 1 9.41365"/>
        <text x="125" y="125" transform="translate(0,35)" text-anchor="middle">
          {{ mile }}
        </text>
      </svg>
    </span>
  </span>
</template>

<script>
import {mapGetters} from 'vuex'
import utilities from '../mixins/utilities'

export default {
  name: 'PortStop',
  mixins: [utilities],
  props: {
    label: String,
    images: Array,
    mile: {
      type: String,
      default: '1'
    }
  },
  computed: {
    ...mapGetters(['portState', 'storyState']),
    length () {
      return (this.time / 10) * 9
    }
  }
}
</script>
