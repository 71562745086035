<template>
  <div class="itinerary-selector">
    <v-select label="name" :options="allItineraries"  :value="selectedItinerary"  placeholder="Where would you like to go?" @input="setItin">
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <svg width="14" height="10" viewBox="0 0 14 10" fill="none"><path d="M1 0.999999L7 9L13 1" stroke="black" stroke-linejoin="bevel"></path></svg>
        </span>
      </template></v-select>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: 'ItinerarySelector',
  components: {
    vSelect
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['allItineraries', 'selectedItinerary']),
  },
  methods: {
    ...mapActions(['setSelectedItinerary']),
  setItin: function(val){
    this.setSelectedItinerary(val)
    this.$emit('refresh')
  }

    //TODO: add actions for when the selection is made: https://vue-select.org/guide/values.html#getting-and-setting
  }
}
</script>
