<template>
  <div class="control-panel">
    <transition :css="false" @appear="enter" @enter="enter" @leave="leave">
      <div class="controls" v-if="openPanel">
        <!-- <label class="control-group">Water current
          <input type="checkbox" name="water_current" v-model="selectedCurrentState">
        </label> -->

        <div class="time-and-temp control-group lined">
          <div>
            <h2 class="date">{{ currentDate }}</h2>
            <p class="time">{{ currentTime }}</p>
          </div>

          <div class="temp">
            {{ cToF(this.tempDisplay).toFixed(1) }}<sup>&deg;<span>F</span></sup>
          </div>
        </div>

        <div class="control-wrapper">
          <!--          <div class="control-group lined">-->
          <!--            <span>{{ activePort ? activePort.name : 'Full journey' }}</span>-->
          <!--          </div>-->

          <div class="control-group velocity">
            <label>Velocity<br> <span>
            <button @click="decrementSpeed">&minus;</button>
            <input class="velocity" type="range" max="1" min="-1" step=".1" v-model="selectedSpeedLevel"/>
            <button @click="incrementSpeed">&plus;</button>
          </span> </label>
          </div>
          <div class="control-group velocity-stat lined">
            <div class="stat"><span></span><span>{{ speedDisplay.toFixed(1) }} ft&#179;/s</span></div>
          </div>

          <div class="control-group lined">
            <span>Depth</span> <span>{{ Math.floor(this.depthDisplay) }} ft
          </span>
          </div>
          <div class="control-group zoom lined">
            <label> Zoom<br> <span>
            <button @click="decrementZoom">&minus;</button>
            <input class="zoom" type="range" max="4" min="1" v-model="selectedZoomLevel"/>
            <button @click="incrementZoom">&plus;</button>
          </span> </label>
          </div>

          <div class="control-group lined">
            <label for="encounters"> Encounters </label>
            <select id="encounters" v-model="currentPort">
              <option value="all">All</option>
              <option v-for="(port, index) in ports" :value="port.id" :key="`port-${index}`">
                {{ port.name }}
              </option>
            </select>
          </div>

          <div class="control-group time-of-day lined">
            <label class="radios">
              <input type="radio" name="time_of_day" value="day" v-model="selectedDayPart">
              <span></span>Day</label>

            <label class="radios">
              <input type="radio" name="time_of_day" value="night" v-model="selectedDayPart">
              <span></span>Night</label>
          </div>

          <!-- <div class="control-group">
            <label for="paddlewheel">Paddle wheel</label>
            <input id="paddlewheel" type="checkbox" v-model="selectedPaddleState">
          </div> -->
          <div class="control-group"><label class="checkboxes" for="marker">Location marker
            <input id="marker" type="checkbox" v-model="selectedMarkerState">
            <span></span> </label>
          </div>
          <div class="control-group"><label class="checkboxes" for="port-label">Port label
            <input id="port-label" type="checkbox" v-model="selectedPortState">
            <span></span> </label>
          </div>
          <div class="control-group"><label class="checkboxes" for="time">Time at port
            <input id="time" type="checkbox" v-model="selectedTimeAtState">
            <span></span> </label>
          </div>
          <div class="control-group"><label class="checkboxes" for="phrase">Phrases
            <input id="phrase" type="checkbox" v-model="selectedPhraseState">
            <span></span> </label>
          </div>

          <template v-if="$mode === 'app'">
            <div class="control-group lined-top">
              <span>PDF Output</span>
            </div>

            <div class="control-group">
              <label>Width
                <input type="number" v-model="pdfWidth">
              </label> <span class="pointer" title="Swap" @click="swapDimensions">&times;</span> <label>Height
              <input type="number" v-model="pdfHeight">
            </label>
            </div>
            <div class="control-group">
              <span>Orientation</span> <span>{{
                orientation
              }}</span>
            </div>
            <div class="control-group zoom">
              <label>Scale {{ pdfScale }}<br> <span>
            <button @click="decrementScale">&minus;</button>
            <input class="scale" type="range" max="100" min="0" step="1" v-model="pdfScale"/>
            <button @click="incrementScale">&plus;</button>
          </span> </label>
            </div>

            <div class="control-group center button lined">
              <button id="pdf" @click="generatePDF">Generate PDF</button>
            </div>
          </template>
        </div>
      </div>
    </transition>
    <div class="control-toggle" :class="{'open':openPanel}" @click="togglePanel">
      <svg width="14" height="10" viewBox="0 0 14 10" fill="none">
        <path d="M1 0.999999L7 9L13 1" stroke="white" stroke-linejoin="bevel"/>
      </svg>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import utilities from '../mixins/utilities'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  name: 'ControlPanel',
  mixins: [utilities],
  data () {
    return {
      openPanel: false,
      currentPort: 'all',
      currentDate: '',
      currentTime: '',
      activePort: null,
      pdfWidth: '17',
      pdfHeight: '11',
      pdfScale: 100
    }
  },
  watch: {
    selectedZoomLevel (val) {
      if (val < 3) {
        this.setStoryState(false)
      } else {
        if (this.currentPort !== 'all') {
          this.setStoryState(true)
        }
      }
    },
    currentPort (val) {
      //If it is not all, toggle on the story-only mode and zoom into level 4
      if (val === 'all') {
        this.setStoryState(false)
        this.activePort = null
      } else {
        this.setZoomLevel(4)
        this.setStoryState(true)
        this.activePort = this.ports.filter(port => port.id === String(val))[0]
      }

      gsap.to(window, {
        scrollTo () {
          return (val === 'any') ? 0 : `#port-${val}`
        }
      })
    }
  },
  computed: {
    ...mapState(['zoomLevel', 'speedLevel', 'markerToggle', 'portToggle', 'timeAtToggle', 'phraseToggle', 'depthAverage', 'tempAverage']),
    ...mapGetters(['controlSpeedLevel', 'currentState', 'dayPart', 'markerState', 'portState', 'timeAtState', 'phraseState', 'storyState', 'ports']),
    selectedDayPart: {
      get () {
        return this.dayPart
      },
      set (val) {
        this.setTimeOfDay(val)
        this.clearAnimationSetup()
      }
    },
    selectedZoomLevel: {
      get () {
        return this.zoomLevel
      },
      set (val) {
        this.setZoomLevel(val)
        this.clearAnimationSetup()
      }
    },
    selectedSpeedLevel: {
      get () {
        return this.speedLevel * -1
      },
      set (val) {
        this.setSpeedLevel(val * -1)
        this.clearAnimationSetup()
      }
    },
    selectedPaddleState: {
      get () {
        return this.paddleToggle
      },
      set (val) {
        this.setPaddleState(val)
        this.clearAnimationSetup()
      }
    },
    selectedMarkerState: {
      get () {
        return this.markerToggle
      },
      set (val) {
        this.setMarkerState(val)
        this.clearAnimationSetup()
      }
    },
    selectedPortState: {
      get () {
        return this.portToggle
      },
      set (val) {
        this.setPortState(val)
        this.clearAnimationSetup()
      }
    },
    selectedTimeAtState: {
      get () {
        return this.timeAtToggle
      },
      set (val) {
        this.setTimeAtState(val)
        this.clearAnimationSetup()
      }
    },
    selectedPhraseState: {
      get () {
        return this.phraseToggle
      },
      set (val) {
        this.setPhraseState(val)
        this.clearAnimationSetup()
      }
    },
    selectedStoryState: {
      get () {
        return this.storyToggle
      },
      set (val) {
        this.setStoryState(val)
        this.clearAnimationSetup()
      }
    },
    selectedCurrentState: {
      get () {
        return this.currentToggle
      },
      set (val) {
        this.setCurrentState(val)
        this.clearAnimationSetup()
      }
    },
    speedDisplay: {
      get () {
        let speed = this.activePort ? this.activePort.averageSpeed : this.getJourneyAverage('averageSpeed')

        if (typeof speed !== 'number') {
          speed = 0
        }
        while (speed < 10000) {
          speed += Math.floor(50 * Math.random())
        }

        return speed + (1000 * this.selectedSpeedLevel)
      }

    },
    tempDisplay: {
      get () {
        if (this.activePort && this.activePort.temperature) {
          return this.activePort.temperature
        }
        return this.tempAverage
      }
    },
    depthDisplay: {
      get () {
        let da = this.activePort ? this.activePort.depth : this.depthAverage

        while (da < 20) {
          da += Math.floor(25 * Math.random())
        }
        return Math.floor(da || this.depthAverage)
      }
    },
    orientation () {
      return (this.pdfWidth === this.pdfHeight) ? 'Square' : (this.pdfWidth > this.pdfHeight) ? 'Landscape' : 'Portrait'
    }
  },
  created () {
    setInterval(this.getNow, 1000)
  },
  mounted () {
    this.$mousetrap.bind('shift+up', this.incrementZoom)
    this.$mousetrap.bind('shift+down', this.decrementZoom)
    this.$mousetrap.bind('shift+right', this.decrementSpeed)
    this.$mousetrap.bind('shift+left', this.incrementSpeed)
  },
  methods: {
    ...mapActions(['setCurrentState', 'setTimeOfDay', 'setZoomLevel', 'setSpeedLevel', 'setPaddleState', 'setMarkerState', 'setPortState', 'setTimeAtState', 'setPhraseState', 'setStoryState']),
    togglePanel () {
      this.openPanel = !this.openPanel
      this.clearAnimationSetup()
    },
    incrementZoom () {
      this.clearAnimationSetup()
      if (this.zoomLevel < 4) {
        this.setZoomLevel(this.zoomLevel + 1)
      } else {
        this.setZoomLevel(4)
      }
    },
    decrementZoom () {
      this.clearAnimationSetup()
      if (this.zoomLevel > 1) {
        this.setZoomLevel(this.zoomLevel - 1)
      } else {
        this.setZoomLevel(1)
      }
    },
    incrementSpeed () {
      if (this.speedLevel < 1) {
        this.setSpeedLevel(this.speedLevel + .1)
      } else {
        this.setSpeedLevel(1)
      }
    },
    decrementSpeed () {
      if (this.speedLevel > -1) {
        this.setSpeedLevel(this.speedLevel - .1)
      } else {
        this.setSpeedLevel(-1)
      }
    },
    incrementScale () {
      if (this.pdfScale < 100) {
        this.pdfScale++
      }
    },
    decrementScale () {
      if (this.pdfScale > 0) {
        this.pdfScale--
      }
    },
    getJourneyAverage: function (prop) {
      let average = this.ports.reduce(function (sum, port) {
        if (Object.prototype.hasOwnProperty.call(port, prop)) {
          return sum + port[prop]
        } else {
          return sum
        }

      }, 0) / this.ports.length

      return average
    },
    getNow: function () {
      const today = new Date()
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const date = month[today.getMonth()] + '. ' + today.getDate() + ', ' + today.getFullYear()
      const time = this.formatAMPM(today)
      this.currentDate = date
      this.currentTime = time
    },
    formatAMPM: function (date) {
      let hours = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      let ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      return hours + ':' + minutes + ':' + seconds + ' ' + ampm
    },
    enter (el, done) {
      gsap.from(el, {
        duration: 1,
        ease: 'power3.inOut',
        height: 0,
        onComplete: done
      })
    },
    leave (el, done) {
      gsap.to(el, {
        duration: 1,
        ease: 'power3.inOut',
        height: 0,
        onComplete: done
      })
    },
    swapDimensions () {
      const oldWidth = this.pdfWidth
      const oldHeight = this.pdfHeight

      this.pdfWidth = oldHeight
      this.pdfHeight = oldWidth
    },
    generatePDF () {
      this.clearAnimationSetup()
      window.createPDF({
        width: this.inchToMicron(parseInt(this.pdfWidth)), height: this.inchToMicron(parseInt(this.pdfHeight))
      }, parseInt(this.pdfScale))
    }
  }
}
</script>
