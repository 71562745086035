import Vue from 'vue';
import VueRouter from 'vue-router';
// import { mapActions, mapState, mapGetters} from 'vuex'
import Main from './components/main.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Main',
        component: Main
    },
    {
        path: '/itinerary/:itineraryId',
        name: 'Itinerary',
        component: Main
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  })

export default router;
